import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const pinia = createPinia()

library.add(faHouse, faPen, faQuoteLeft, faBookOpen, faCircleInfo)

const firebaseConfig = {
  apiKey: "AIzaSyDmjcvDwyiiOEkWRUs2N7zUN1-sDnjOZNM",
  authDomain: "el-bicho-de-los-dibujos.firebaseapp.com",
  projectId: "el-bicho-de-los-dibujos",
  storageBucket: "el-bicho-de-los-dibujos.appspot.com",
  messagingSenderId: "916700393497",
  appId: "1:916700393497:web:2c88b53859ee2d30fbfa88"
}
  
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(pinia).mount('#app')
import 'bootstrap/dist/js/bootstrap.js'

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: '/drawings',
        name: 'Drawings',
        component: () => import('../views/drawings/DrawingsPage.vue'),
      },
      {
        path: '/thoughts',
        name: 'Thoughts',
        component: () => import('../views/thoughts/ThoughtPage.vue'),
      },
      {
        path: '/stories',
        name: 'Stories',
        component: () => import('../views/stories/StoriesPage.vue'),
      },
      {
        path: '/storie/:id_story/chapters',
        name: 'Chapters',
        component: () => import('../views/stories/ChaptersPage.vue'),
      },
      {
        path: '/storie/:id_story/chapter/:id_chapter/page/:numero_pagina',
        name: 'Pages',
        component: () => import('../views/stories/PagesPage.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/ErrorNotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    name: 'bad-not-found',
    component: () => import('../views/ErrorNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
